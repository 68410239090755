import { Routes } from '@angular/router';

import { PAGE_TITLE_SUFFIX } from './shared/constants';
import { AuthGuard } from './shared/guards';

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./landing-page/landing-page.routes').then((m) => m.routes)
  },
  // There is no conflict among routes here, it is safe to use ''.
  {
    path: '',
    loadChildren: () =>
      import('./project-manager/account/account.routes').then(
        (m) => m.ACCOUNT_ROUTES
      )
  },
  {
    path: 'docs',
    loadChildren: () => import('./docs/docs.routes').then((m) => m.ROUTES)
  },
  {
    path: 'cl',
    loadChildren: () =>
      import('./classification/classification.routes').then(
        (m) => m.CLASSIFICATION_ROUTES
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'not-found',
    loadComponent: () =>
      import('./not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
    title: 'Page Not Found' + PAGE_TITLE_SUFFIX
  },
  {
    path: 'pm',
    loadChildren: () =>
      import('./project-manager/project-manager.routes').then(
        (m) => m.PROJECT_MANAGER_ROUTES
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'st',
    loadChildren: () =>
      import('./span-task/span-task.routes').then((m) => m.SPAN_TASK_ROUTES),
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: '/not-found' }
];
