import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withInMemoryScrolling,
  withRouterConfig
} from '@angular/router';
import { provideMarkdown } from 'ngx-markdown';

import { DataStandComponent } from '@data-stand/data-stand.component';
import { APP_ROUTES } from '@data-stand/data-stand.routes';
import { environment } from '@data-stand/environments';
import { NotFoundInterceptor, TokenInterceptor } from '@shared/interceptors';

if (environment.production) enableProdMode();

bootstrapApplication(DataStandComponent, {
  providers: [
    provideRouter(
      APP_ROUTES,
      withRouterConfig({
        paramsInheritanceStrategy: 'always'
      }),
      // Scroll to top when navigating to a new route.
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled'
      })
    ),
    importProvidersFrom(
      BrowserModule,
      // Dev/prod conditioned imports.
      environment.imports
    ),
    provideMarkdown(),
    // Set default duration for the snack-bar.
    // https://material.angular.io/components/snack-bar/overview
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
        horizontalPosition: 'left'
      }
    },
    // Use the outline appearance for all form fields.
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'dynamic' }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotFoundInterceptor,
      multi: true
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    // Set format for Material Datepicker.
    // https://material.angular.io/components/datepicker/overview#choosing-a-date-implementation-and-date-format-settings
    provideMomentDateAdapter({
      parse: {
        dateInput: 'YYYY-MM-DD'
      },
      display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
      }
    })
  ]
}).catch((err) => console.error(err));
